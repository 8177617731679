require('./bootstrap');


import {createApp, defineAsyncComponent} from "vue";


const app = createApp({});


app.component('RenderPreview', defineAsyncComponent(() => import('./components/RenderPreview')))

app.mount('#app')
